/* Components */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body  {
  background: linear-gradient(217deg, rgba(212, 154, 125, 1), rgba(81, 31, 99, 1) 70.71%),
  linear-gradient(127deg, rgba(0,255,0,.8), rgba(255, 210, 0, 1) 70.71%),
  linear-gradient(336deg, rgba(255, 210, 0, 1), rgba(0,0,255,0) 70.71%);
  background-repeat: repeat-x;
}

.Footer {
  background-color: rgba(81, 31, 99, 1) 70.71%;
  color: white;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: calc(2px + 2vmin);
  max-width: 100%;
  justify-content: center;
  text-align: center;
}
.SlideShowbody  {
  background: white
}

.grow {
  font-family: 'Merienda';
  color: gold;
  font-style: oblique;
  font-weight: bolder;
  -webkit-animation-name: grow;
  -webkit-animation-duration: 4s;
  transition-property: -moz-transform;
  transition-duration: 1s;
  animation-name: grow;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
  
}

/*Slideshow*/
.images {
  padding: 20px 0;
  overflow: hidden;
  white-space: nowrap;
  background: white;
  position: relative;
}

/* .images:hover .images-slide {
  animation-play-state: paused;
} */

/* .images:before, 
.images:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: '';
  z-index: 2;
}
.images:before {
  left: 0;
  background: linear-gradient(to left, rgba(255,255,255,0),  #eb57c3)
}

.images:after {
  right: 0;
  background: linear-gradient(to right, rgba(255,255,255,0),#40f8f8)
} */

.images-slide {
  display: inline-block;
  white-space: nowrap;
  animation: 20s slide infinite linear;
 
}
.images-slide img{
  height: 150px;
  width: 150px;
  margin: 0 40px;
  border-radius: 100px;
}

/* Animations */
.shimmer {
  position: absolute;
  top: 0;
  left: 0;
   width: 50%;
   height: 100%;

  background: linear-gradient(100deg, rgba(255,255,255,0) 20%, rgba(255,255,255,005) 50%,rgba(255,255,255,0) 80%);
  animation: shimmer 1.5s infinite linear;
}
.grainy {
position: relative;
width: 100%;
height: 100%;
display: flex;
justify-content: left;
align-items: center;
background: linear-gradient(140deg ,#eb57c3, #40f8f8);
z-index: 1;
}

.grainy::before {
content: '';
position: absolute;
top: 0;
width: 100%;
height: 100%;
background-color: url('https://i.ibb.co/2qpvRHf/rusty-grit.jpg') repeat center;
background-size: 100% 100%;
mix-blend-mode: overlay;
z-index: -1;
}

.grainy::after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(140deg, #eb57c3, #40f8f8);
  filter: blur(9px);
  z-index: -2;
  }

@keyframes shimmer {
  0% {
    transform: translateX(-300%);
  }
  100%{
    transform: translateX(300%);
  }
}
@keyframes grow {
  from {
    -webkit-transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.5);
  }
}

.App-header {
   min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: relative;
}

#logo {
width: 8rem;
}

/* Fonts */
.dancing-script {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.playfair-display  {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.textShadow {
font-size: 80px;
text-shadow: 2px 2px 5px yellow;
}

@media (max-width: 2460px) {
  body {
      font-size: 20px;
  }
}

@media (max-width: 2340px) {
  body {
      font-size: 19px;
  }
}

@media (max-width: 2220px) {
  body {
      font-size: 18px;
  }
}

@media (max-width: 2100px) {
  body {
      font-size: 17px;
  }
}

@media (max-width: 1980px) {
  body {
      font-size: 16px;
  }
}

@media (max-width: 1860px) {
  body {
      font-size: 15px;
  }
}

@media (max-width: 1740px) {
  body {
      font-size: 14px;
  }
}

@media (max-width: 1620px) {
  body {
      font-size: 13px;
  }
}

@media (max-width: 1500px) {
  body {
      font-size: 12px;
  }
}

@media (max-width: 1380px) {
  body {
      font-size: 11px;
  }
}

@media (max-width: 1260px) {
  body {
      font-size: 10px;
  }
}

@media (max-width: 1140px) {
  body {
      font-size: 9px;
  }
}

@media (max-width: 1020px) {
  body {
      font-size: 8px;
  }
}

@media (max-width: 900px) {
  body {
      font-size: 7px;
  }
}

@media (max-width: 780px) {
  body {
      font-size: 6px;
  }
}

@media (max-width: 660px) {
  body {
      font-size: 5px;
  }
}

@media (max-width: 540px) {
  body {
      font-size: 4px;
  }
}